import React, { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';


const AddReactToolTip = () => {
    useEffect(() => {
        ReactTooltip.rebuild();
      });
    return  <ReactTooltip className="extraClass" />;
}
 
export default AddReactToolTip;