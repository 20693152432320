import React, { Component } from "react";
import { MeetingSessionStatusCode } from "amazon-chime-sdk-js";

class AudioVideoObserver extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    const { audioVideo } = window;
    if (audioVideo) audioVideo.addObserver(this);
  }

  componentWillUnmount() {
    const { audioVideo } = window;
    if (audioVideo) {
      audioVideo.removeObserver(this);
    }
  }

  audioVideoDidStop(sessionStatus) {
    console.log(`session stopped from ${JSON.stringify(sessionStatus)}`);
    //postChimeLogs();=>check this later if intereseting
    const { onMeetingEnded } = this.props;
    if (sessionStatus.statusCode() === MeetingSessionStatusCode.MeetingEnded) {
      console.log(`meeting ended`);
      //window.location = `${window.location.search}#leave`;
      if (typeof onMeetingEnded === "function") onMeetingEnded();
    } else if (sessionStatus.statusCode() === MeetingSessionStatusCode.Left) {
      console.log("left meeting");
    }
  }
  render() {
    return <></>;
  }
}

export default AudioVideoObserver;
