import { useState, useCallback } from "react";
import _ from "lodash";


const AUTH_DATA = {'Authorization':  `Basic ${btoa("m-events:kims")}`};
const useHttp = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const sendRequest = useCallback(async (requestConfig, applyDataFn, onErrorFn) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(requestConfig.url, {
          method: requestConfig.method ? requestConfig.method : "GET",
          headers: requestConfig.headers ? _.merge(requestConfig.headers, AUTH_DATA)  : AUTH_DATA,
          body: requestConfig.body ? JSON.stringify(requestConfig.body): null,
      });

      if (!response.ok) {
        throw new Error("Request failed!");
      }

      const data = await response.json();
      applyDataFn(data);

    } catch (err) {
      setError(err.message || "Something went wrong!");
      if(typeof onErrorFn === "function")
        onErrorFn(err.message);
    }
    setIsLoading(false);
  },[]);
  return {
      isLoading,
      error,
      sendRequest
  }
};

export default useHttp;
