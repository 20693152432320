import svgMute from "./../img/svg-general/volume-mute.svg";
import svgDown from "./../img/svg-general/volume-down.svg";
import svgUp from "./../img/svg-general/volume-up.svg";
const Controls = ({onMute, onVolumeChange, muted, volumeValue}) => {
  return (
    <div className="me-audio-only-chime__controls">
      <div className={`mute-container btn-action ${muted?"is-muted":""}`} onClick={onMute} data-tip={muted ? "unmute":"mute"}>
          <img src={svgMute} alt="mute" className={muted ? "":""} />
      </div>
      <div className="volume-controls">
        <div className={`btn-action ${volumeValue === 0 ? "me-disabled":""}`} onClick={()=>onVolumeChange(null,-1)} data-tip={"decrease volume by 10%"}>
          <img src={svgDown} alt="volume down" />
        </div>
        <div className="range-container">
          <input
            type="range"
            className="custom-range"
            id="customRange1"
            value={muted ? 0 : volumeValue * 100}
            onChange={(e) => onVolumeChange(e.target.value)}
          />
        </div>
        <div className={`btn-action ${volumeValue === 1 ? "me-disabled":""}`} onClick={()=>onVolumeChange(null,1)} data-tip={"increase volume by 10%"}>
          <img src={svgUp} alt="volume up"/>
        </div>
      </div>
    </div>
  );
};

export default Controls;
