import React, { useContext } from "react";
import "./Apps.scss";
import VmContext from "./store/vm-store";
import UserInfoScreen from "./components/common/UserInfoScreen";
import Spinner from "./components/common/Spinner";
import Meeting from "./components/Meetings";
import UsernameForm from "./components/UsernameForm";
import AddReactToolTip from "./components/common/AddReactToolTip";
import CameraPermission from "./components/chime/CameraPermission";

function App() {
  const {
    error,
    loadingApp,
    config,
    awsMeeting,
    oldName,
    showCustomUX,
    onSetUsername,
    showLeaveScreen,
    onCleanOldName,
    meetingEnded,
  } = useContext(VmContext);

  const onFormSubmit = (name, isRemember) => {
    onSetUsername(name, isRemember);
  };

  if (error)
    return (
      <UserInfoScreen
        onButtonClick={() => this.setState({ joinError: false })}
        title={error?.title}
        text={error?.text}
        cssClass="danger"
      />
    );
  if (loadingApp) return <Spinner show />;

  let jsxContent = <UsernameForm text={config?.text} onSubmit={onFormSubmit} oldName={oldName} onCleanOldName={onCleanOldName}/>;
  
  if (awsMeeting?.started) jsxContent = <Meeting />;
  if (showLeaveScreen)
    jsxContent = (
      <UserInfoScreen
        title={"Info"}
        text={<p>Your session has ended. Thank you for attending this session.</p>}
        cssClass="info"
      />
    );
  if(meetingEnded)
    jsxContent = (
      <UserInfoScreen
        title={"Info"}
        text={<p>Meeting session has ended. Thank you for attending.</p>}
        cssClass="info"
      />
    );

    if(showCustomUX)
    return <CameraPermission
                show
                accessBlocked={true} />
  return (
    <div className="me-audio-only-chime container">
      <AddReactToolTip />
      {jsxContent}
    </div>
  );
}

export default App;
