import _ from "lodash";
import { isNullOrUndefined } from "./Validator";
import { inflate64 } from "./PakoHelper";
import { isTechMode } from "./Helper";

const getQSValue = (pQsKey) => {
  if (isNullOrUndefined(pQsKey)) return null;

  const lQsKey = pQsKey.toLowerCase();
  const lUrlParams = getUrlParamsCaseIS(); //NOTE: make it case insensitive

  let lQsValue = lUrlParams.get(lQsKey);

  //3rd fallback check token
  if (!lQsValue) {
    lQsValue = getFromToken(lQsKey);
  }

  return lQsValue;
};

let finalTokenData = null;
let inflateStr = null;
const getFromToken = (pQsKey) => {
  try {
    if (!finalTokenData) {
      const qsToken = getToken();
      if (!qsToken) return null;
      //console.time("sd")
      let tokenObj = inflateStr ? inflateStr : inflate64(qsToken);
      if (!inflateStr) inflateStr = tokenObj; //remember token to speed up a littles

      if (isTechMode()) console.log(tokenObj);
      if (_.isString(tokenObj)) {
        tokenObj = JSON.parse(tokenObj);
        //Translate new to old Pramerter Names
        if (tokenObj.VmSessionId) tokenObj.sid = tokenObj.VmSessionId;
        if (tokenObj.sid) tokenObj.s_id = tokenObj.sid;
        if (tokenObj.VmUserId) tokenObj.uid = tokenObj.VmUserId;
        if (tokenObj.uid) tokenObj.u_id = tokenObj.uid;
        if (tokenObj.VmTalkId) tokenObj.resource_id = tokenObj.VmTalkId;
        if (tokenObj.SpeakerFullName)
          tokenObj.autojoinas = tokenObj.SpeakerFullName;
        if (tokenObj.SplitSystemVm)
          tokenObj.app_system = tokenObj.SplitSystemVm;
        if (tokenObj.VmRoomId) tokenObj.rid = tokenObj.VmRoomId;

        Object.entries(tokenObj).forEach(([key, value]) => {
          tokenObj[key.toLowerCase()] = value;
        });
      }
      //console.timeEnd("sd")
      if (!_.isObject(tokenObj)) return null;

      finalTokenData = tokenObj;
    }

    let lValue = finalTokenData[pQsKey];
    return lValue ? lValue : null;
  } catch (err) {
    console.log(err);
  }

  return null;
};
window.getFromToken = getFromToken;

//NOTE: make it case insensitive
const getUrlParamsCaseIS = () => {
  const qsParams = new URLSearchParams(window.location.search);
  let lUrlParams = new URLSearchParams();
  for (const [name, value] of qsParams) {
    lUrlParams.append(name.toLowerCase(), value);
  }
  return lUrlParams;
};

export const getToken = () => {
  const urlParams = getUrlParamsCaseIS();
  const qsToken = urlParams.get("token");
  return qsToken ? qsToken : null;
};

export const getSessionId = () => {
  let lSessionId = getQSValue("sid");
  if (!lSessionId) lSessionId = getQSValue("s_id");
  return lSessionId;
};

export const getTechParam = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get("tech");
};

export const getRoomParam = () => getQSValue("room");
export const getAccesskeyParam = () => getQSValue("accesskey");
export const getSignalrUrl = () => getQSValue("signalr_url");
//const urlParams = new URLSearchParams(window.location.search);
export const getBaseUrl = () => getQSValue("base_url");
export const getIsReplica = () => getQSValue("is_replica") == "1" ? true : false;
