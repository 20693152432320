import Backdrop from "../common/Backdrop";
import {
  isFirefox
} from "react-device-detect";
import "./CameraPermission.css";

const CameraPermission = (props) => {
  const {
    show,
    accessBlocked,
    txtPermissionTitle,
    txtPermissionText,
    txtAccessBlockedText,
    txtPermissionHelpTitle,
    txtPermissionHelpText
  } = props;

  const getCssClasses =()=>{
    let cssClasses =[];

    if(accessBlocked)
      cssClasses.push("blocked");
    if(isFirefox)
      cssClasses.push("firefox");

    return cssClasses.join(" ");
  };

  const getAllowAccessImg =()=>{
    return (`content/img/allowAccessDeviceLabels${isFirefox?"FF":""}.png`);
  }

  const renderTitle = () => {
    if (accessBlocked) return null;
    return <h2 className="mb-2">{txtPermissionTitle}</h2>;
  };

  const renderText = () => {
    if (accessBlocked)
      return (<>
          <p dangerouslySetInnerHTML={{ __html: txtAccessBlockedText }}></p>
          <img className="" src="content/img/allowhelp.png" alt="info" />
        </>);
    return (
      <>
        <img className="" src={getAllowAccessImg()} alt="info" />
        <p>{txtPermissionText}</p>
        <div className="help-info-content">
          {txtPermissionHelpTitle && (
            <div className="heading">
              <i className="fas fa-phone-square-alt mr-3"></i>
              <span
                dangerouslySetInnerHTML={{ __html: txtPermissionHelpTitle }}
              ></span>
            </div>
          )}

          {txtPermissionHelpText && (
            <p dangerouslySetInnerHTML={{ __html: txtPermissionHelpText }}></p>
          )}
        </div>
      </>
    );
  };

  return (
    <div
      className={`camera-permission-screen ${getCssClasses()}`}
    >
      <Backdrop show={show} />
      <img className="arrow" src="content/img/arrow.png" alt="arrow" />
      <div className="content">
        {renderTitle()}
        {renderText()}
        {accessBlocked && <div className="mt-3">
          <button className="btn btn-outline-light" onClick={()=>window.location.reload()}>
            <i className="fas fa-sync-alt mr-3"></i>
            Reload
         </button>
        </div>}
      </div>
    </div>
  );
};

CameraPermission.defaultProps = {
  txtPermissionTitle: "Unable to get device labels.",
  txtPermissionText:
    "Please allow us the access of your Microphone and Camera to participate.",
  txtAccessBlockedText: `You blocked the access to your camera and microphone.<br />
      Please allow us the access of your microphone and camera to participate.`,
  txtPermissionHelpTitle:"Need help ?",
  txtPermissionHelpText:`Call +49 69 3807 9883<br />
                and enter your MeetingID <a target="_blank"  href="https://m-events.zoom.us/j/96216670303" >962 166 703 03</a> <br />
                or <a target="_blank"  href="https://support.m-events.com" >click here</a> to open helpdesk-chat.`,  
};

export default CameraPermission;
