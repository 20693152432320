//import svgAudio from "./../img/svg-loaders/oval.svg"
import svgOvalSpinner from "./../../img/svg-loaders/oval.svg";
import styled from "styled-components";

const Center = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align:center;
`;
const Img = styled.img`
    background-color: rgba(0,0,0,.3);
    padding: .5rem;
    border-radius: 4px;
    width:75px
`;

const Spinner = ({show, text="Loading..."}) => {
    if(!show)
    return null;
    return ( 
            <Center>
                <Img src={svgOvalSpinner} alt="loader"/><br/>
                {text}
            </Center>
     );
}
 
export default Spinner;