export const settings = {
  sponsorImgUrl:null,
  isReplicatedMeetingMode:true,
  text: {
    txtYourNameLbl:"Your name",
    txtYourNamePlaceholder:"type your name here...",
    txtRemeberMyName: "Remember my name",
    txtFormInfo:"Save your name for future meetings on this device",
    txtJoin:"Join",

    txtLeave:"Leave",
    txtLeaveHeading:"Info",
    txtLeaveText:"Your session has ended. Thank you for attending this session",
    txtModalLeaveHeading :"Leaving session?",
    txtModalLeaveText :"Are you sure that you want to leave this session?",
    
  },
};
