import React, { Component, useState, useLayoutEffect } from "react";


const AudioMeeting =React.forwardRef( (props, ref) => {
  

  useLayoutEffect(()=>{
    (async()=>{
        const { audioVideo } = window;
        const audioMix = ref?.current; //document.getElementById("meeting-audio");
        await audioVideo.unbindAudioElement();
        await audioVideo.bindAudioElement(audioMix);

        audioMix.volume = props?.volumeValue || .7;

        //Emitter.on(emitTypes.ON_SPEAKER_EVENT, this.onSpeakerEvent);
        //Emitter.on(emitTypes.ON_SPEAKER_CHANGE_EVENT, this.onSpeakerDeviceChange);

        return ()=>{
            const { audioVideo } = window;
            //audioVideo.realtimeUnsubscribeToMuteAndUnmuteLocalAudio(this.onMuteUnmuteHandler);
            //audioVideo.realtimeUnsubscribeToSetCanUnmuteLocalAudio(this.onCanUnmuteHandler);
        };
    })();
  },[])
  return (
    <>
      <audio ref={ref} id="meeting-audio-id" className="hide"></audio>
    </>
  );
});

export default AudioMeeting;
