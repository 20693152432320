import React, { useRef, useState } from "react";
import { isNullOrWhitespace } from "../utils/Validator";
import ProgressBar from "./ui/PogressBar";

const UsernameForm = ({ onSubmit, onCleanOldName, text, oldName }) => {
  const {
    txtYourNameLbl,
    txtYourNamePlaceholder,
    txtRemeberMyName,
    txtFormInfo,
    txtJoin,
  } = text;
  const [error, setError] = useState(null);
  const [ignoreOldName, setIgnoreOldName] = useState(null);
  const [showProgress, setShowProgress] = useState(false);
  const inputRef = useRef(null);
  const checkBoxRef = useRef(null);
  const onSubmitHandler = (e) => {
    e.preventDefault();
    let name = inputRef?.current?.value;
    if (oldName && !ignoreOldName) name = oldName;
    if (isNullOrWhitespace(name) || name?.length < 4) {
      setError({ text: "Name should be at least 4 characters long." });
      return;
    }
    setError(null);
    if (typeof onSubmit === "function"){
      onSubmit(name, checkBoxRef?.current?.checked);
      setShowProgress(true);
    }
    console.log(name);
  };

  let controlsJsx = (
    <>
      {" "}
      <div className="form-group">
        <label htmlFor="exampleInputEmail1">{txtYourNameLbl}</label>
        <input
          type="text"
          className={`form-control ${error ? "val-error-input" : ""}`}
          ref={inputRef}
          placeholder={txtYourNamePlaceholder}
        />
        {error && (
          <small className="form-text my-2 val-error-text">{error?.text}</small>
        )}
      </div>
      <div className="form-check">
        <input
          type="checkbox"
          className="form-check-input"
          id="chkRemember"
          ref={checkBoxRef}
        />
        <label className="form-check-label" htmlFor="chkRemember">
          {txtRemeberMyName}
          <small id="emailHelp" className="form-text text-muted  my-2">
            {txtFormInfo}
          </small>
        </label>
      </div>
    </>
  );
  if (oldName && !ignoreOldName)
    controlsJsx = (
      <div className="previos-meetings-msg">
        <small>You previously attended meeting(s) with the name:</small>
        <div className="me-form-control">
          <input
            type="text"
            className="form-control"
            id="inputPassword"
            readOnly
            value={oldName}
          />
          <div
            className="lblChange btn"
            onClick={() => {
              onCleanOldName();
              setIgnoreOldName(true);
            }}
          >
            <i className="far fa-edit"></i> Change
          </div>
        </div>
        <small>
          If you want to join with this name click join, otherwise change.
        </small>
      </div>
    );

  return (
    <form
      className="me-audio-only-chime__username-form"
      onSubmit={onSubmitHandler}
    >
      {controlsJsx}
      <button type="submit" className="btn btn-black btn-block">
        {txtJoin}
      </button>
        <ProgressBar show={showProgress}/>
    </form>
  );
};

export default UsernameForm;
