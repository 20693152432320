import {
  ConsoleLogger,
  DefaultDeviceController,
  DefaultMeetingSession,
  LogLevel,
  MeetingSessionConfiguration,
  Versioning,
  DefaultMeetingReadinessChecker
  /*AllHighestVideoBandwidthPolicy
    MeetingSessionStatusCode,
    DefaultModality,
    DefaultBrowserBehavior,
    DefaultActiveSpeakerPolicy*/
} from "amazon-chime-sdk-js";
//import { uuidv4 } from 'uuidv4';
//import { toast } from 'react-toastify';
//import Emitter from "./../services/EventEmitter";
//import * as emitTypes from "./../services/emitTypes";

import {
  getJoinDefaultSettings,
  getSignalrBaseUrl,
} from "./../utils/Helper";
import { makeHttpCall } from "./HttpHelpers";
//import {toastConfig} from "./../settings/appConfig";

const authenticate = async (pMeeting, pName, pRegion, isReplicatedMeetingMode) => {
  window.meeting = pMeeting;
  window.name = pName;
  let resp = null;
  let joinInfo = null;

  const url = `${getSignalrBaseUrl()}/ChairTbl/PostJoin`;
  const lDefaultSettingsObj = getJoinDefaultSettings();
  lDefaultSettingsObj.IsMicrophoneMuted = true;

  resp = await makeHttpCall({
    url,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    bodyJson: {
      ChimeSessionTitle: pMeeting,
      UserFullName: pName,
      AwsRegion: pRegion,
      //UserId: getAnyUserId(),
      TheSettings: lDefaultSettingsObj,
      AdditionalInfos: window.navigator.appVersion,
      IsReplicaMeeting: isReplicatedMeetingMode? true: false
    },
  });
  if(!resp.ok)
    return null;
  joinInfo = {
    Meeting: { Meeting: resp.TheMeeting },
    Attendee: { Attendee: resp.TheAttendee },
  };
  joinInfo.TheSettings = resp.TheSettings ? resp.TheSettings : null;

  //console.log(resp);

  //1st part
  const logger = new ConsoleLogger("ChimeMeetingLogs", LogLevel.WARN);
  const deviceController = new DefaultDeviceController(logger);
  const configuration = new MeetingSessionConfiguration(
    joinInfo.Meeting,
    joinInfo.Attendee
  );
  
  if(!isReplicatedMeetingMode)
    configuration.attendeePresenceTimeoutMs = 5000;

  const meetingSession = new DefaultMeetingSession(
    configuration,
    logger,
    deviceController
  );
 

  const audioVideo = meetingSession.audioVideo;
  window.audioVideo = audioVideo;
  window.meetingSession = meetingSession;
  window.meetingLogger = logger;
  window.chosenVideoTransformDevice = undefined;
  window.awsSdkVersion = Versioning.sdkVersion;

  window.meetingReadinessChecker = new DefaultMeetingReadinessChecker(
    logger,
    meetingSession
  );

  await initializeMeetingSession(configuration);
  //TODO: enable this later
  /*const url = new URL(window.location.href);
    url.searchParams.set('m', window.meeting);
    history.replaceState({}, `${window.meeting}`, url.toString());*/

  window.deviceController = deviceController;
  window.configuration = configuration;

  //return configuration.meetingId;
  window.meetingId = configuration.meetingId;
  return {
    id: configuration.meetingId,
    joinInfo,
  };
};

const initializeMeetingSession = async (configuration) => {
  //let logger = new ConsoleLogger('SDK', LogLevel.INFO);
  /*
    //this.audioVideo.addDeviceChangeObserver(this);
    //this.setupDeviceLabelTrigger();
    await mtDevices.populateAllDeviceLists(audioVideo);

    ////NOTE:add observers (important)
    ////this.setupMuteHandleimport { makeHttpCall } from './HttpHelpers';
r();
    ////this.setupCanUnmuteHandler();
    //setupSubscribeToAttendeeIdPresenceHandler();
    ////this.setupDataMessage();
    window.audioVideo.addObserver(observers.AudioVideo);
    window.audioVideo.addContentShareObserver(observers.ContentShare);
    mtContentShare.initContentShareDropDownItems();
    */
};

export { authenticate };
