import React from "react";
import ReactDOM from "react-dom";
import Backdrop from "./Backdrop";
import "./UserInfoScreen.css";
//import styled from "styled-components";

const UserInfoScreen = ({ title, text, showBtn, onButtonClick, cssClass, children }) => {
  let btnJsx = null;
  if (showBtn)
    btnJsx = (
      <button
        className={`btn btn-lg btn-light btn-block text-jme-darkblue`}
        type="submit"
      >
        OK
      </button>
    );

  const renderUserInfo = () => {
    return (
      <>
        <Backdrop show />
        <div className="container user-info-screen">
          <form
            id="form-failed-meeting"
            onSubmit={(e) => {
              e.preventDefault();
              onButtonClick();
            }}
          >
            <div className={`card mb-3 bg-light`}>
              <div id="failed-meeting" className="card-header bg-light">
                <h3 className={`card-title mb-0 text-${cssClass}`}>{title}</h3>
              </div>
              <div className="card-body">
                <div className="card-text">{text}</div>
                <small id="failed-meeting-error" className="text-muted"></small>
                {children}
              </div>
            </div>
            {btnJsx}
          </form>
        </div>
      </>
    );
  };

  return ReactDOM.createPortal(
    renderUserInfo(),
    document.querySelector("#general-info")
  );
};

export default UserInfoScreen;
