import React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import Backdrop from "./Backdrop";
import "./Modal.css";

const ModalContainer = styled.div`
  transform: ${(props) =>
    props.show ? "translateY(0)" : "translateY(-100vh)"};
  opacity: ${(props) => (props.show ? "1" : "0")};
  pointer-events: ${(props) => (props.show ? "auto" : "none")};
`;

const Modal = (props) => {
  const renderModal = () => {
    const {
      onYesClick,
      onNoClick,
      txtId,
      show,
      hideBtnYes,
      hideBtnCancel,
      hideControlButtons,
      showHeaderExitIcon,
      positionCenter,
      txtBtnYes,
      txtBtnCancel,
    } = props;

    return (
      <>
        <Backdrop show={show} zIndex={props.zIndex} />
        <ModalContainer
          id={txtId}
          className={`Modal text-center ${positionCenter ? "center" : ""}`}
          show={show}
        >
          {showHeaderExitIcon && (
            <div className="pb-1 exit-container">
              <i
                className="far fa-window-close"
                onClick={onNoClick}
                title="Close"
              ></i>
            </div>
          )}
          {props.children}
          {!hideControlButtons && (
            <div className="footer row m-0 mt-3 justify-content-center">
              <div  className={`col-5 ${hideBtnCancel ? "hide" : ""}`}>
                <button
                  className="btn btn-block btn-outline-secondary"
                  onClick={onNoClick}
                >
                  {txtBtnCancel}
                </button>
              </div>
              <div className={`col-5 ${hideBtnYes ? "hide" : ""}`}>
                <button className="btn btn-block btn-dark" onClick={onYesClick}>
                  {txtBtnYes}
                </button>
              </div>
            </div>
          )}
        </ModalContainer>
      </>
    );
  };

  return ReactDOM.createPortal(renderModal(), document.querySelector("#modal"));
};

Modal.defaultProps = {
  txtBtnYes: "Yes",
  txtBtnCancel: "Cancel",
  zIndex: 100,
  txtId:"txtIdModal",
  hideControlButtons: false,
  hideBtnCancel:false,
  positionCenter: false,
  showHeaderExitIcon: false,
};
export default Modal;
