/* eslint-disable no-throw-literal */
/* eslint-disable no-useless-escape */

export const getURLParamsObj = function () {
  var params = {};
  var currentURL = window.location.href,
      hash = window.location.hash;
  if(hash.length > 0)
    currentURL = currentURL.replace(hash, "");
  var paramArray = currentURL.split("?");
  if (paramArray.length === 2) {
    var tmpArray = paramArray[1].split("&");
    for (var i = 0; i < tmpArray.length; i++) {
      var param = tmpArray[i];
      var array = param.split("=");
      if (array[1] !== "*") {
        params[array[0]] = array[1];
      }
    }
  }
  return params;
};

export const getRandomNumber = () => {
  const min = 1;
  const max = 1000000000;
  const rand = min + Math.random() * (max - min);
  return Math.floor(rand);
};

export const isHttp =()=>{
    try{
        const {location} = window;
        if(location.hostname === "127.0.0.1" || location.hostname === "localhost")
            return false;
        if (location.protocol !== 'https:')
            return true;
        

    }catch(err){console.log(err);}
    return false;
}

//await delay(5000);
export const delay = ms => new Promise(res => setTimeout(res, ms));


export const setCssVariable=(variableName, variableValue)=> {
    try{
      var r = document.querySelector(':root');
      r.style.setProperty(variableName, variableValue);
      //.setProperty('--my-variable-name', 'pink');
    }catch(err){console.log(err);}
  }
  

  export const loadScript = async (
    { src, integrity, crossOrigin },
    timeoutMs = 7000
  ) => {
    // the tf library loading order must be followed
  
    await new Promise((resolve, reject) => {
      //const  head = document.getElementsByTagName('head')[0];
      const script = document.createElement("script");
      const timer = setTimeout(() => {
        reject(new Error(`Loading script ${src} takes longer than ${timeoutMs}`));
      }, timeoutMs);
      script.onload = function (_ev) {
        clearTimeout(timer);
        resolve();
      };
      script.onerror = function (_ev) {
        clearTimeout(timer);
        reject(new Error(`Failed to load ${src}`));
      };
      if (integrity) script.integrity = integrity;
      if (crossOrigin) script.crossOrigin = crossOrigin;
      script.src = src;
      document.body.appendChild(script);
    });
  };
  
  export const loadCss = async (
    {src, domId},
    timeoutMs = 7000
  ) => {
    // the tf library loading order must be followed
    if(document.getElementById(domId) || !domId){
      return new Promise((resolve, reject) =>   reject(new Error(`css file with id=${domId} and src= ${src} alreyd exists`)))
    }
  
    await new Promise((resolve, reject) => {
      const  head = document.getElementsByTagName('head')[0];
      const fileRef = document.createElement("link");
      const timer = setTimeout(() => {
        reject(new Error(`Loading css ${src} takes longer than ${timeoutMs}`));
      }, timeoutMs);
      fileRef.onload = function (_ev) {
        clearTimeout(timer);
        resolve();
      };
      fileRef.onerror = function (_ev) {
        clearTimeout(timer);
        reject(new Error(`Failed to load ${src}`));
      };
      
      fileRef.href  = src;
      fileRef.id  = domId;
      fileRef.rel = "stylesheet";
      fileRef.type = "text/css";
      head.appendChild(fileRef);
    });
  };