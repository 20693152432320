/* eslint-disable no-unused-vars */
import React, {
  useState,
  useEffect,
  useCallback,
  useLayoutEffect,
  useRef,
} from "react";
import _ from "lodash";
import { isIOS } from "react-device-detect";
import { PermissionDeniedError, NotReadableError } from "amazon-chime-sdk-js";

import { isNullOrWhitespace, isNumber } from "../utils/Validator";
import { delay, loadScript } from "./../utils/Tools";
import useHttp from "./../hooks/use-http";
import { getSessionId } from "../utils/QueryString";
import { getVmBaseUrl } from "../utils/Helper";
import { makeHttpCall } from "../utils/HttpHelpers";
import { getSignalrBaseUrl } from "./../utils/Helper";
import { settings } from "./config";
import { authenticate } from "../utils/ChimeManager";
import { REGION } from "../constants/appConst";
import { getSignalRGroupName } from "./../utils/Helper";
import { get_translations } from "./helper";
import * as WebStorage from "./../utils/LocalStorage";
import { getIsReplica } from './../utils/QueryString';

const USERNAME_KEY = "audio.chime.username";

const VmContext = React.createContext({
  onQnaConfig: (pQnaConfig) => {},
  onQnaTextConfig: (pQnaText) => {},
});

export const VmContextProvider = (props) => {
  const [loadingApp, setLoadingApp] = useState(true);
  const [session, setSesssion] = useState(null);
  const [error, setError] = useState(null);
  const [appSettings, setAppSettings] = useState(settings);
  const [username, setUsername] = useState(null);
  const [oldName, setOldName] = useState(null);
  const [awsMeeting, setAwsMeeting] = useState(null);
  const [showLeaveScreen, setShowLeaveScreen] = useState(false);
  const [meetingEnded, setMeetingEnded] = useState(null);
  const [showCustomUX, setShowCustomUX] = useState(null);
  const [devicesAccessBlocked, setDevicesAccessBlocked] = useState(null);

  const { sendRequest, isLoading } = useHttp();
  const { WebSocketRails, $ } = window;

  useLayoutEffect(() => {
    (async () => {
      const qsSid = getSessionId();
      if (!qsSid) {
        setError({
          title: "Missing data",
          text: "Could not start without a session information. Maybe forgot to add qs parameter?",
        });
        return;
      }
      /*if (typeof WebSocketRails === "undefined")
        await loadScript({
          src: "https://edge.conference2web.com/globals/websocket/websocket_rails.0.0.1.min.js",
        });
      if (!$.connection?.chimeHub)
        await loadScript({
          src: `${getSignalrBaseUrl()}/signalr/hubs`,
        });
*/

      const resp = await makeHttpCall({
        url: `https://${getVmBaseUrl()}/v1/contentsessions/${qsSid}.json?include_resourcegroups=1`,
        isVm: true,
      });
      if (!resp.ok) {
        setError({
          title: "Fetching failed",
          text: "Failed by getting session information.",
        });
        return;
      }
      setSesssion(resp);

      const respConfig = await get_translations(qsSid);
      console.log(respConfig);
      let appSetting = settings;
      if (
        respConfig?.ok &&
        respConfig?.data?.[0]?.translationkey?.translations?.[0]?.value
      ) {
        const appConfigTranslation = JSON.parse(
          respConfig?.data?.[0]?.translationkey?.translations?.[0]?.value
        );
        appSetting = _.merge(appSetting, appConfigTranslation);
      }
      
      if(getIsReplica())
        appSetting.isReplicatedMeetingMode = true;

      const oldUserName = WebStorage.get(USERNAME_KEY);
      if (oldUserName?.length > 0) setOldName(oldUserName);
      setAppSettings(appSetting);
      setLoadingApp(false);
    })();
    return () => {};
  }, [sendRequest]);

  useEffect(() => {
    (async () => {
      if (!isNullOrWhitespace(username) && !awsMeeting) {
        getAwsData(username);
      }
    })();
  }, [username, awsMeeting]);

  useEffect(() => {
    (async () => {
      if (showCustomUX === false) {
        console.log("showCustomUX === false");
        startChime();
      }
    })();
  }, [showCustomUX]);

  const appHeight =useCallback(() => {
    const doc = document.documentElement
    doc.style.setProperty('--app-height', `${window.innerHeight}px`)
  },[]);

  useEffect(()=>{
    window.addEventListener('resize', appHeight);
    appHeight();
    return ()=>window.removeEventListener('resize', appHeight);
  },[appHeight]);

  const startChime = async () => {
    await delay(1.5 * 1000);
    window.audioVideo.realtimeMuteLocalAudio();
    window.audioVideo.start();
    setAwsMeeting((prev) => {
      return { ...prev, started: true };
    });
  };

  const askPermission = async () => {
    const { meetingSession } = window;
    //Emitter.emit("TEST", sessionStatus);
    meetingSession.audioVideo.setDeviceLabelTrigger(async () => {
      // For example, let the user know that the browser is asking for microphone and camera permissions.
      const devices = await navigator.mediaDevices.enumerateDevices();
      const hasAccess = devices.find(c=>c.kind == 'audioinput' && c.label);
      
      //console.log(hasAccess)
      if(!hasAccess)
        setShowCustomUX(true);
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setShowCustomUX(false);
      return stream;
    });
  };
  const fakeUserMediaCall = async () => {
    const { meetingSession } = window;
    //const {devicesAccessBlocked} = this.state;
    //fake
    //const videoDevices = await window.audioVideo.listVideoInputDevices();
    try {
      
      const audioInputDevices = await window.audioVideo.listAudioInputDevices();
      await window.audioVideo.startAudioInput(audioInputDevices[0]);
      const audioOutputDeviceInfo = meetingSession.audioVideo.listAudioOutputDevices();
      await meetingSession.audioVideo.chooseAudioOutput(audioOutputDeviceInfo[0].deviceId);
      //setDevicesAccessBlocked(true);
    } catch (e) {
      if (e instanceof PermissionDeniedError) {
        console.error("Permission denied", e);
      } else if (e instanceof NotReadableError) {
        console.error("Not Readable", e);
      }
    }
  };

  const getAwsData = async (username) => {
    try {
      const region = REGION;
      const userName = username;
      const title = getSignalRGroupName(session?.id);
      //do auth
      const resp = await authenticate(title, userName, region, appSettings?.isReplicatedMeetingMode);
      //const res = await authenticate(title, userName, region);
    
      if (!resp) {
        setError({
          title: "SignalR server error",
          text: "Was not able to get meta information about the meeting.",
        });
        return;
      }

      setAwsMeeting(resp);
      // if (isIOS) {
      //   await askPermission();
      //   await fakeUserMediaCall();
      // } else 
      {

        startChime();
      }
     
    } catch (err) {
      console.log(err);
      //this.setState({ joinError: true, loading: false });
    }
  };

  const onSetUsername = (name, isRemember) => {
    setUsername(name);
    if (isRemember) WebStorage.store(USERNAME_KEY, name);
  };

  const onLeave = () => {
    window.audioVideo.stop();
    setShowLeaveScreen(true);
  };

  const onMeetingEnded =()=>{
    setMeetingEnded(true);
  };

  //console.log(perPage)
  return (
    <VmContext.Provider
      value={{
        loadingApp,
        error,
        session,
        config: appSettings,
        oldName,
        onSetUsername,
        awsMeeting,
        showCustomUX,
        devicesAccessBlocked,
        onLeave,
        onMeetingEnded,
        showLeaveScreen,
        meetingEnded,
        onCleanOldName: () => WebStorage.remove(USERNAME_KEY),
      }}
    >
      {props.children}
    </VmContext.Provider>
  );
};

export default VmContext;
