import React from "react";
import _ from "lodash";
import "./SoundBars.scss";
const SoundBars = ({visibile}) => {
  return (
    <div className={`sound-bars ${visibile ? "":"invisible"}`}>
      <div>
        {_.range(3).map((c, index) => (
          <span key={index} className="single-sound-bar"></span>
        ))}
      </div>
    </div>
  );
};

export default SoundBars;
