import React, {useEffect} from 'react';
import {getCurrentAttendeId, getSignalrBaseUrl} from "../../utils/Helper";
//import {isConnected} from "../../services/signalRChime";


const OnWindowsBeforeUnload = ({showModal}) => {

    const onUnload = e => { 
        //e.preventDefault();
        //e.returnValue = 'dsd';
        ////1st try socket
        // const {server}= window.$.connection.chimeHub;
        // if(server?.onLeave && isConnected())
        //   server.onLeave(window.meeting, getCurrentAttendeId());
        //2nd try sendBacon
        if (window.navigator.sendBeacon){
          let formData = new FormData();
          formData.append("pAccessKey", window.meeting);
          formData.append("pAttendeeId", getCurrentAttendeId());
          formData.append("pMeetingId", window.meetingId);
          window.navigator.sendBeacon(`${getSignalrBaseUrl()}/ChairTbl/PostLeaveMeeting`, formData);
        }

        if(showModal){
          e = e || window.event;
          // For IE and Firefox prior to version 4
          if (e) {
              e.returnValue = 'Are you sure that you want to quit without submitting your recording?';
          }
          // For Safari
          return 'Are you sure that you want to quit without submitting your recording?';
        }
      }

    useEffect(() => {
        // code to run on component mount
        window.addEventListener("beforeunload", onUnload);
        return () => {
            // Clean up the subscription
            window.removeEventListener("beforeunload", onUnload);
        };
      }, [showModal])

    return ( <></>);
}
 
export default OnWindowsBeforeUnload;