import { getVmBaseUrl } from "./../utils/Helper";
import { makeHttpCall } from "./../utils/HttpHelpers";



export const get_translations = async (sid) => {
  const url = `https://${getVmBaseUrl()}/v1/translationkey_mappings.json`;
  let translatable_type = null;
  let translatable_id = null;

  if (sid) {
    translatable_type = "Contentsession";
    translatable_id = sid;
  }
  if(!translatable_type)
  return  new Promise((resolve, reject) => {
    resolve(null);
  });
  const params = {
    translation_language: "chime.audio.read-only.config",
    include_translations: 1,
    include_translatable: 1,
    translatable_type,
    translatable_id,
  };
  const result = await makeHttpCall({
    url : `${url}?${Object.keys(params).map(key=>`${key}=${params[key]}`).join('&')}`,
    method: "GET",
    isVm: true,
  });
  return result;
};
