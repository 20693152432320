const ProgressBar = ({show, value=100}) => {
  return (
    <div
      id="progress-join"
      className={`w-100 progress progress-hidden mt-3 ${
        show ? "" : "hide"
      }`}
    >
      <div
        className="progress-bar progress-bar-striped progress-bar-animated"
        style={{width:`${value}%`}}
        role="progressbar"
        aria-valuenow="100"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
  );
};

export default ProgressBar;
