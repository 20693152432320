/* eslint-disable eqeqeq */
import _ from "lodash";

import {
  getRoomParam,
  getTechParam,
  getSignalrUrl,
  getAccesskeyParam,
  getBaseUrl,
} from "./QueryString";


export const isTechMode = () => {
  const qsTech = getTechParam();
  return _.includes(["1", 1, "true"], qsTech);
};


export const getCurrentAttendeId = () => {
  const { meetingSession } = window;
  try {
    return meetingSession.configuration.credentials.attendeeId;
  } catch (err) {
    console.log(err);
  }

  return null;
};

export const getSignalRGroupName = (id) => {
  const lQsAccessKey = getAccesskeyParam();
  if (lQsAccessKey) return lQsAccessKey;

  const lQSRoom = getRoomParam();
  if (lQSRoom) return `vid_escrsmw2021_${lQSRoom}`;
  return `vid_escrsmw2021_sid_${id}`;
};

export const getQSSignalrUrl = () => {
  const qsSignalUrl = getSignalrUrl();
  if (qsSignalUrl) return decodeURIComponent(qsSignalUrl);

  return null;
};

export const getSignalrBaseUrl = () => {
  let qsSignalUrl = getQSSignalrUrl(),
    baseUrlSignalR = "https://signalr.m-events.com";
  //https://signalrtest.m-events.com
  if (qsSignalUrl) baseUrlSignalR = qsSignalUrl;
  return baseUrlSignalR;
};

export const getVmSocketBaseUrl = () => {
  return getVmBaseUrl();
};
export const getVmBaseUrl = () => {
  let baseUrl = "api-lb.virtual-meeting.net";
  const qsUrl = getBaseUrl();
  if (qsUrl) baseUrl = qsUrl;
  return baseUrl;
};

export const getJoinDefaultSettings = () => {
  return {
    IsSoundMuted: false,
    //IsSoundButtonVisible : true,
    IsMicrophoneMuted: true,
    //IsMicrophoneButtonVisible : true,
    IsWebcamEnabled: false,
    //IsWebcamButtonVisible : true,
    IsRemoteControlEnabled: false,
    //IsRemoteControlButtonEnabled :false,
    IsNativeScreenshareButtonVisible: false,
  };
};

export const isDeviceInList = (devices, device) => {
  try {
    if (!device || !devices) return false;

    return devices.findIndex((el) => el.deviceId === device.deviceId) === -1
      ? false
      : true;
  } catch (err) {
    console.log(err);
  }

  return false;
};

export const checkImage = (path, ignoreOnError = false) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(path);
    img.onerror = () => (ignoreOnError ? resolve("fake") : reject());
    img.src = path;
  });
};
