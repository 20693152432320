import React, { useContext, useRef, useEffect, useState } from "react";

import VmContext from "./../store/vm-store";
import sponsorImgDefault from "./../img/SponsorPlaceholder.png";
import Controls from "./Controls";
import AudioMeeting from "./AudioMeetings";
import Modal from "./common/Modal";
import SoundBars from "./ui/SoundBars";
import Attendees from './chime/Attendees';
import OnWindowsBeforeUnload from './common/OnWindowsBeforeUnload';
import AudioVideoObserver from './chime/AudioVideoObserver';

const Meeting = () => {
  const { session, onLeave, config, onMeetingEnded } = useContext(VmContext);
  const [muted, setMuted] = useState(false);
  const [volumeValue, setVolumeValue] = useState(0.7);
  const [showModal, setShowModal] = useState(false);
  const [attendeSpeaks, setAttendeSpeaks] = useState(false);
  const audioRef = useRef(null);
  const canvasRef = useRef(null);
  useEffect(() => {
    setTimeout(() => {
      if (audioRef?.current) {
        audioRef.current.muted = false; 
      }
    }, 200);
  }, []);

  const onMuteHandler = () => {
    setMuted((prev) => {
      audioRef.current.muted = !prev;
      return !prev;
    });
  };

  const onVolumeChangeHandler = (value, btnTarget) => {
    let newVolumeValue = volumeValue;
    if (btnTarget) {
      //btns
      if (btnTarget > 0 && volumeValue < 1) {
        newVolumeValue += 0.1;
      } else if (volumeValue > 0) {
        newVolumeValue -= 0.1;
      }
      if (newVolumeValue > 1) newVolumeValue = 1;
      if (newVolumeValue < 0) newVolumeValue = 0;
    } else {
      //manual
      console.log(value);
      newVolumeValue = value / 100;
    }

    audioRef.current.volume = newVolumeValue;
    setVolumeValue(newVolumeValue);
  };


  const sponsorImg = config?.sponsorImgUrl?.length > 0 ? config?.sponsorImgUrl: sponsorImgDefault;

  return (
    <>
      <OnWindowsBeforeUnload />
      <Attendees onActiveSpeaker={(hasActive)=>setAttendeSpeaks(hasActive)} />
      <AudioVideoObserver onMeetingEnded={onMeetingEnded} />
      <Modal
        show={showModal}
        onYesClick={onLeave}
        onNoClick={() => setShowModal(false)}
      >
        <h3>{config?.text?.txtModalLeaveHeading}</h3>
        <div className="body my-4">{config?.text?.txtModalLeaveText}</div>
      </Modal>

      <div className="me-audio-only-chime__session-title">
        <div className="line-clamp-2">{session?.title}</div>
      </div>
      <div className="me-audio-only-chime__sponsor-container">
        <img src={sponsorImg} alt="logo" />
      </div>
      {/* <div ref={canvasRef} style={{height:"70px"}}></div> */}
      <SoundBars visibile={attendeSpeaks} />
      <Controls
        onMute={onMuteHandler}
        onVolumeChange={onVolumeChangeHandler}
        muted={muted}
        volumeValue={volumeValue}
      />
      <div className="me-audio-only-chime__leave-container">
        <button
          className="btn btn-dark btn-block"
          onClick={() => setShowModal(true)}
        >
          {config?.text?.txtLeave}
        </button>
      </div>
      <AudioMeeting ref={audioRef} volumeValue={volumeValue} />
    </>
  );
};

export default Meeting;
