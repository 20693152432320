import React from "react";
//import ReactDOM from "react-dom/client";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { VmContextProvider } from "./store/vm-store";

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <VmContextProvider>
//     <App />
//   </VmContextProvider>
// );
ReactDOM.render(
  <VmContextProvider>
  <App />
</VmContextProvider>,
  document.getElementById('root')
);
