import React, { useEffect, useCallback, useState, useRef } from "react";
import {
  DefaultModality,
  DefaultActiveSpeakerPolicy,
} from "amazon-chime-sdk-js";

let showActiveSpeakerScores = false;

const Attendees = ({onActiveSpeaker}) => {
  const rosterRef = useRef({});


  
  const attendeeIdPresenceHandler =
    (attendeeId, present, externalUserId, dropped) => {
      const {audioVideo} = window;
      
      console.log(`${attendeeId} present = ${present} (${externalUserId})`);
      const isContentAttendee = new DefaultModality(attendeeId).hasModality(
        DefaultModality.MODALITY_CONTENT
      );
      /*const isSelfAttendee =
      new DefaultModality(attendeeId).base() ===
      meetingSession.configuration.credentials.attendeeId;*/
      if (!present) {
        delete rosterRef.current[attendeeId];
        console.log(`${attendeeId} dropped = ${dropped} (${externalUserId})`);
        return;
      }
     

        
      if (!rosterRef.current[attendeeId]) {
        let lNewUserName =
          externalUserId.split("#").slice(-1)[0] +
          (isContentAttendee ? " «Content»" : "");

          rosterRef.current[attendeeId] = {
          name: lNewUserName,
          isChairRole: lNewUserName.toLocaleLowerCase()?.includes("chair"),
        };
        
      }
      audioVideo.realtimeSubscribeToVolumeIndicator(
        attendeeId,
        async (attendeeId, volume, muted, signalStrength) => {
          
          if (!rosterRef.current[attendeeId]) {
            return;
          }
          if (volume !== null) {
            rosterRef.current[attendeeId].volume = Math.round(volume * 100);
          }
          if (muted !== null) {
            rosterRef.current[attendeeId].muted = muted;
          }
          if (signalStrength !== null) {
            rosterRef.current[attendeeId].signalStrength = Math.round(
              signalStrength * 100
            );
          }
          //setRoster(lRoster);
          //updateRoster
        }
      );
    };

  const activeSpeakerHandler = 
    (attendeeIds) => {
      let hasActive = false;
      for (const attendeeId in rosterRef.current) {
        rosterRef.current[attendeeId].active = false;
      }
      for (const attendeeId of attendeeIds) {
        if (rosterRef.current[attendeeId]) {
          rosterRef.current[attendeeId].active = true;
          hasActive = true;
          break; // only show the most active speaker
        }
      }
      onActiveSpeaker(hasActive);
      console.log("activeSpeakerHandler")
    };

  const setupSubscribeToAttendeeIdPresenceHandler = () => {
    const {audioVideo} = window;
    //const audioVideo = window.audioVideo;

    audioVideo.realtimeSubscribeToAttendeeIdPresence(
      attendeeIdPresenceHandler
    );
    audioVideo.subscribeToActiveSpeakerDetector(
      new DefaultActiveSpeakerPolicy(),
      activeSpeakerHandler,
      (scores) => {
        for (const attendeeId in scores) {
          if (rosterRef.current[attendeeId]) {
            rosterRef.current[attendeeId].score = scores[attendeeId];
          }
        }
        //setRoster(lRoster);
      },
      showActiveSpeakerScores ? 100 : 0
    );
  };

  useEffect(() => {

    setupSubscribeToAttendeeIdPresenceHandler();
    return () => {
        const audioVideo = window.audioVideo;
      if (audioVideo) {
        audioVideo.realtimeUnsubscribeToAttendeeIdPresence(
          attendeeIdPresenceHandler
        );
        audioVideo.unsubscribeFromActiveSpeakerDetector(activeSpeakerHandler);
      }
    };
  }, []);
  return <></>;
};

export default Attendees;
